// extracted by mini-css-extract-plugin
export var gridList = "RelatedPostsList-module--gridList--64d8f";
export var heading = "RelatedPostsList-module--heading--6b665";
export var opacityEnter = "RelatedPostsList-module--opacityEnter--70697";
export var opacityExit = "RelatedPostsList-module--opacityExit--b214f";
export var rollDown = "RelatedPostsList-module--rollDown--0d335";
export var rollUp = "RelatedPostsList-module--rollUp--58583";
export var slideInDown = "RelatedPostsList-module--slideInDown--1424e";
export var slideOutUp = "RelatedPostsList-module--slideOutUp--6a9ad";
export var splashEnter = "RelatedPostsList-module--splashEnter--f04d7";
export var splashExit = "RelatedPostsList-module--splashExit--ea8fa";
export var wrapper = "RelatedPostsList-module--wrapper--c8a79";