// extracted by mini-css-extract-plugin
export var card = "PostCard-module--card--41336";
export var category = "PostCard-module--category--3c610";
export var description = "PostCard-module--description--d33eb";
export var heading = "PostCard-module--heading--6189b";
export var image = "PostCard-module--image--a1916";
export var logo = "PostCard-module--logo--4dce7";
export var logoWrapper = "PostCard-module--logoWrapper--750e9";
export var newsDate = "PostCard-module--newsDate--c7d9a";
export var opacityEnter = "PostCard-module--opacityEnter--759e5";
export var opacityExit = "PostCard-module--opacityExit--19a54";
export var readMore = "PostCard-module--readMore--4d1fb";
export var rollDown = "PostCard-module--rollDown--92a15";
export var rollUp = "PostCard-module--rollUp--7c69e";
export var slideInDown = "PostCard-module--slideInDown--0e75d";
export var slideOutUp = "PostCard-module--slideOutUp--af0ca";
export var splashEnter = "PostCard-module--splashEnter--ce8e5";
export var splashExit = "PostCard-module--splashExit--788c9";
export var type = "PostCard-module--type--176aa";
export var wrapper = "PostCard-module--wrapper--63fe8";