import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { createPropertyIfNotEmptyValue } from '@/base/helpers/objects';
import { getPageCategoryFromUrl, removeHTMLTags } from '@/base/helpers/strings';
import { windowGlobal } from '@/base/utils/isWindowAvailable';
import Icon from '@/components/elements/Icon';
import Image from '@/components/elements/Image';
import TypeWithDate from '@/components/elements/TypeWithDate/TypeWithDate';
import generatePagePath from '~/config/generatePagePath';
import * as styles from './PostCard.module.scss';
import type { PostCardProps, PostCardTypes } from './PostCard.types';

export default function PostCard({
  content,
  full_slug,
  first_published_at,
  name,
  label,

  position = 0,
  displayGenericPostsCategories,
}: StoryblokEntry<PostCardTypes> & PostCardProps) {
  const { image, card_image, excerpt, external_link, category, type, logo, news_category, event_type, date } =
    content || {};

  const [trackingProps, setTrackingProps] = React.useState({});
  const isBlog = full_slug.includes('blog/');
  const isCustomer = full_slug.includes('customers/');
  const typeVarName = isBlog ? category : type;
  const cardImage = typeof image === 'object' ? image?.image : card_image ? card_image : image;

  function validateImageUrl(imageUrl: string) {
    return imageUrl.match(/\/\/a\.storyblok\.com\//i) && imageUrl.match(/\.(jpeg|jpg|gif|png)$/);
  }

  function isHighResolutionImage(imageUrl?: string) {
    if (!imageUrl) return;

    const isValid = validateImageUrl(imageUrl);
    if (!isValid) return;

    const size = imageUrl.match(/[0-9]+x[0-9]+/g)?.[0];
    if (!size) return;

    const width = Number(size.split('x')[0]);
    return width > 350;
  }

  function renderImage() {
    return cardImage && isHighResolutionImage(cardImage) ? (
      <Image data={cardImage} fluid className={styles.image} imageQuality={100} />
    ) : (
      <div className={styles.image} style={{ backgroundImage: `url(${cardImage})` }} />
    );
  }

  React.useEffect(() => {
    setTrackingProps({
      'data-navigation-type': 'Card',
      ...createPropertyIfNotEmptyValue('data-card-title', String(name)),
      ...createPropertyIfNotEmptyValue('data-card-position', String(position + 1)),
      ...createPropertyIfNotEmptyValue('data-card-location', getPageCategoryFromUrl(windowGlobal)),
    });
  }, []);

  function renderCardBody() {
    return (
      <>
        {cardImage && renderImage()}

        {/* Blog label - If needed static label */}
        {displayGenericPostsCategories && isBlog ? <TypeWithDate type="blog" className={styles.type} /> : null}

        {/* Blog & Resources label - If no static label */}
        {!isBlog || !displayGenericPostsCategories ? (
          <TypeWithDate type={typeVarName} date={isBlog ? first_published_at : ''} className={styles.type} />
        ) : null}

        {/* News label */}
        <TypeWithDate type={news_category} date={first_published_at} className={styles.type} />

        {/* Events & Webinars label */}
        {!type && event_type && <TypeWithDate type={event_type} date={date} className={styles.type} />}

        {logo && (
          <div className={styles.logoWrapper}>
            <Image data={logo} fluid className={styles.logo} />
          </div>
        )}

        <h3 className={styles.heading}>{isCustomer ? excerpt : name}</h3>
        {!isCustomer && (
          <div className={styles.description}>
            <p>{removeHTMLTags(excerpt)}</p>
          </div>
        )}

        {label && (
          <p className={styles.readMore}>
            {label}
            <span>
              <Icon>&#xf178;</Icon>
            </span>
          </p>
        )}
      </>
    );
  }

  return (
    <li className={styles.wrapper}>
      {external_link ? (
        <a href={external_link} target="_blank" rel="noreferrer" className={`box ${styles.card}`} {...trackingProps}>
          {renderCardBody()}
        </a>
      ) : (
        <GatsbyLink to={generatePagePath(full_slug)} className={`box ${styles.card}`} {...trackingProps}>
          {renderCardBody()}
        </GatsbyLink>
      )}
    </li>
  );
}
