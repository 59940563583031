import React, { useContext } from 'react';
import { ConfigContext } from '@/base/context/Config/provider';
import { resolveLabelBySlug } from '@/base/helpers/strings';
import PostCard from '../PostCard';
import ModuleWrapper from '../ModuleWrapper/ModuleWrapper';
import type { RelatedPostsListType } from './RelatedPostsList.types';
import * as styles from './RelatedPostsList.module.scss';

export default function RelatedPostsList({
  title,
  className,
  posts,
  usedInModularTemplate,
  genericPostsCategories,
  ...props
}: RelatedPostsListType) {
  const {
    state: { config },
  } = useContext(ConfigContext);

  if (!posts?.length) return null;
  return (
    <ModuleWrapper
      topSpacing={usedInModularTemplate ? 'none' : 'md'}
      bottomSpacing={usedInModularTemplate ? 'none' : 'md'}
      mobileTopSpacing={usedInModularTemplate ? 'none' : 'sm'}
      mobileBottomSpacing={usedInModularTemplate ? 'none' : 'sm'}
      customStyle={{ paddingTop: 0 }}
      {...props}
    >
      <div className={styles.wrapper} data-used-in-modular={String(!!usedInModularTemplate)}>
        <h2 className={styles.heading}>{title}</h2>
        <ul className={`${styles.gridList} ${className || ''}`}>
          {posts.map((card, index) => (
            <PostCard
              {...card}
              label={
                card.content?.card_link_label || resolveLabelBySlug(card.full_slug, config) || config?.card_link_label
              }
              key={card.uuid}
              position={index}
              displayGenericPostsCategories={genericPostsCategories}
            />
          ))}
        </ul>
      </div>
    </ModuleWrapper>
  );
}
