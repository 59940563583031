import React from 'react';
import { shortcutLiteralMonthDateFormat } from '@/base/helpers/dates';
import { removeHypehenat, removePrefix } from '@/base/helpers/strings';
import type { TypeWithDateTypes } from './TypeWithDate.types';
import * as styles from './TypeWithDate.module.scss';

export default function TypeWithDate({ type, date, className }: TypeWithDateTypes) {
  if (!type) return null;

  function renderType(type: string | Array<string>) {
    if (Array.isArray(type)) return removePrefix(removeHypehenat(type[0]), '/');
    return removePrefix(type, '/')?.toLowerCase() === 'e-book' ? 'E-book' : removePrefix(removeHypehenat(type), '/');
  }

  return (
    <p className={`${styles.category} ${className ? className : ''}`}>
      {renderType(type)} {date && <span className={styles.date}>{shortcutLiteralMonthDateFormat(date)}</span>}
    </p>
  );
}
